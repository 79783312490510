import * as React from "react"
import Layout from "../components/Layout";
import localize from "../components/localize";
import {graphql} from "gatsby";
import Landing from "../components/Landing";
import SEO from "../components/SEO";
import Arrow from "../components/Arrow";
import Angebote from "../components/Angebote";
import Kunden from "../components/Kunden";
import Vita from "../components/Vita";
import CallToAction from "../components/CallToAction";

export const query = graphql`
    {
    site {
    siteMetadata {
    lang{
        _type
        en
        de
        }
        angebot{
        _type
        en
        de
        }
        kunden{
        _type
        en
        de
        }
        mehrEntdecken{
        _type
        en
        de
        }
        impressum{
        _type
        en
        de
        }
        datenschutz{
        _type
        en
        de
        }
        agbs{
        _type
        en
        de
        }
        telefon{
        _type
        en
        de
        }
        startseite{
        _type
        en
        de
        }
        download{
        _type
        en
        de
        }
    }
    }
    allSanityStartseite {
    edges {
      node {
        title {
          en
          de
          _type
        }
        quote {
          en
          de
          _type
        }
        image {
          asset {
            gatsbyImageData(
            placeholder: NONE
            )
          }
        }
        angebote {
          slug {
            current
          }
            _rawSubheadline
          _rawTextoverview
          _rawTitle
          _rawTags
          pageactive
          icon{ asset {gatsbyImageData}}
        }
        kundenlogos {
          asset {
            gatsbyImageData
          }
        }
        testimonials {
          _rawText
          _rawJob
          name
        }
        linkedincv
        vitaheadline {
          _type
          de
          en
        }
        logocolor
        vitaimage {
          asset {
            gatsbyImageData(
            placeholder: NONE
            )
          }
        }
        vitaquote {
          en
          de
          _type
        }
        vitasubheadline {
          en
          de
          _type
        }
        vitasignature { asset { gatsbyImageData }}
        _rawVitatext
        _rawCtatext
        ctalink1text{
            en
            de
            _type
        }
        ctalink1
        ctalink2text{
            en
            de
            _type
        }
        ctalink2
        
      }
    }
  }
  allSanitySettings {
    edges {
      node {
      standorte {
          street
          plz
          _rawStadt
          _rawName
        }
        telefon
        skype
        email
        linkedin
        jobsexternal
        jobsexternallink
      }
     }
     }
     allSanityNetzwerk {
    edges {
      node {
      title{
      en
      de
      _type
      }
      }
      }
      }
    }
   `;

const IndexPage = ({data, location}) => {

    const page = data.allSanityStartseite.edges[0].node;

    const settings = data.allSanitySettings.edges[0].node;
    const netzwerk = data.allSanityNetzwerk.edges[0].node;

  return (
      <div id={"top"}>
          <Layout location={location} metadata={data.site.siteMetadata} settings={settings} netzwerk={netzwerk.title}>
              <SEO />
              <Landing image={page.image} quote={page.quote} text={page.title}></Landing>
              <Angebote angebote={page.angebote} location={location} entdecken={data.site.siteMetadata.mehrEntdecken}></Angebote>
              <Kunden testimonials={page.testimonials} logos={page.kundenlogos} kunden={data.site.siteMetadata.kunden} grey={!page.logocolor}></Kunden>
              <Vita signature={page.vitasignature} linkedin={page.linkedincv} text={page._rawVitatext} subheadline={page.vitasubheadline} headline={page.vitaheadline} quote={page.vitaquote} image={page.vitaimage} download={data.site.siteMetadata.download}></Vita>
              {page._rawCtatext ? <CallToAction text={page._rawCtatext} link1={page.ctalink1} link2={page.ctalink2} linktext1={page.ctalink1text} linktext2={page.ctalink2text}></CallToAction> : <></>}
          </Layout>
      </div>
  )
}

export default localize(IndexPage)

//
