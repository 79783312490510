import React from "react";
import Div100vh from "react-div-100vh";
import MyPortableText from "./MyPortableText";
import Arrow from "./Arrow";
import {Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/css";
import Marquee from "react-fast-marquee";
import {GatsbyImage} from "gatsby-plugin-image";
import {localeLink} from "../util";

const CallToAction = ({text, link1, link2, linktext1, linktext2}) => {

    return (
        <div className={"calltoaction__outer bg-light color-blue"} id={"calltoaction"}>
            <div className="calltoaction__inner">
                <div className="portablecenter">
                    <MyPortableText value={text}></MyPortableText>
                </div>
                <div className="btn__container">
                    {link1 && linktext1 ? <a href={link1} className="btn bg-orange-dark color-light uppercase hover-moveup">{linktext1}</a> : <></>}
                    {link2 && linktext2 ? <a href={link2} className="btn bg-orange-dark color-light uppercase hover-moveup">{linktext2}</a> : <></>}
                </div>
            </div>
        </div>
    )
}

export default CallToAction;
